import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import toast, { Toaster } from 'react-hot-toast';
import './App.css';

const CONTRACT_ADDRESS = '0xA0875db1562e16Ca9a15420464ac82947F424FFa';

const canGetBlockNumber = (timeout: number): Promise<boolean> =>
  new Promise((resolve) => {
    if (!window.ethereum) {
      resolve(false);

      return;
    }

    let connected = undefined as boolean | undefined;

    const setTimeoutId = setTimeout(() => {
      if (connected === undefined) {
        connected = false;

        resolve(false);
      }
    }, timeout);

    window.ethereum.request({ method: 'eth_blockNumber', params: [] }).then(
      (blockNumber: number) => {
        if (connected === undefined) {
          clearTimeout(setTimeoutId);
          connected = true;

          resolve(true);
        }
      },
      () => {
        if (connected === undefined) {
          clearTimeout(setTimeoutId);
          connected = false;

          resolve(false);
        }
      },
    );
  });

const reloadContractValue = (contract: ethers.Contract, callback: (value: string) => void) => {
  contract
    .get()
    .then((value: string) => callback(value))
    .catch((error: any) => {
      console.error('MetaMask cannot get contract value. Error:', error);
    });
};

const PleaseChangeNetworkMessage =
  'Please change the network to Ropsten Test Network in order to interact with the contract.';

const CannotAccessBlockChainMessage = `MetaMask cannot access blockchain data.
This may be caused by limited site access. Please allow MetaMask to access on all sites and reload page as a workaround.`;

function App() {
  const [isMetaMaskActive, setIsMetaMaskActive] = useState<boolean>();
  const [isRopstenNetwork, setIsRopstenNetwork] = useState<boolean>();
  const [isChainConnectionHealthy, setIsChainConnectionHealthy] = useState<boolean>();

  const providerRef = useRef<ethers.providers.Web3Provider>();
  const contractRef = useRef<ethers.Contract>();
  const signerRef = useRef<ethers.Signer>();

  const [userInput, setUserInput] = useState('');
  const [signedUserInput, setSignedUserInput] = useState('');
  const [contractValue, setContractValue] = useState('');

  const [isSigningUserInput, setIsSigningUserInput] = useState(false);
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);

  useEffect(() => {
    // detect MetaMask environment
    if (!window.ethereum) {
      setIsMetaMaskActive(false);

      return;
    }

    setIsMetaMaskActive(true);

    window.ethereum
      .request({ method: 'eth_chainId' })
      .then((chainId: string) => setIsRopstenNetwork(chainId === '0x3'));

    canGetBlockNumber(2000).then(setIsChainConnectionHealthy);

    // setup MetaMask listeners
    window.ethereum.on('chainChanged', () => window.location.reload()); // reload page on chain changed

    window.ethereum.on('accountsChanged', ([account]: string[]) => {
      if (!account) {
        signerRef.current = undefined;

        toast('Disconnected.');

        return;
      }

      if (signerRef.current) {
        setSignedUserInput('');

        toast(`Account changed to ${account.slice(0, 30)}...`);
      }
    });

    return () => window.ethereum.removeAllListeners();
  }, []);

  // setup provider if MetaMask is active
  useEffect(() => {
    if (isMetaMaskActive && !providerRef.current) {
      providerRef.current = new ethers.providers.Web3Provider(window.ethereum);
    }
  }, [isMetaMaskActive]);

  // setup contract if network is ropsten
  useEffect(() => {
    if (isRopstenNetwork && !contractRef.current) {
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        ['function get() view returns (string)', 'function set(string)'],
        providerRef.current,
      );

      contractRef.current = contract;

      reloadContractValue(contract, setContractValue);
    } else if (isRopstenNetwork === false) {
      toast(PleaseChangeNetworkMessage);
    }
  }, [isRopstenNetwork]);

  // remind user if MetaMask cannot access blockchain data
  useEffect(() => {
    if (isChainConnectionHealthy === false) {
      toast(CannotAccessBlockChainMessage);
    }
  }, [isChainConnectionHealthy]);

  // show error messages when action is not allowed
  const debugEnvironment = useCallback(() => {
    if (isMetaMaskActive === undefined) return;

    if (isMetaMaskActive === false) {
      toast('MetaMask not enabled.');

      return;
    }

    if (!signerRef.current) {
      toast('Please connect to MetaMask first.');
    }

    if (isRopstenNetwork === false) {
      setTimeout(() => toast(PleaseChangeNetworkMessage), 400);
    }

    if (isChainConnectionHealthy === false) {
      setTimeout(() => toast(CannotAccessBlockChainMessage, { duration: 8000 }), 800);
    }

    return;
  }, [isMetaMaskActive, isChainConnectionHealthy, isRopstenNetwork]);

  // connect to wallet handler
  const connect = useCallback(() => {
    const provider = providerRef.current;

    if (!provider) {
      debugEnvironment();

      return;
    }

    provider
      .send('eth_requestAccounts', [])
      .then((accounts) => {
        const [account] = accounts;

        // setup signer
        signerRef.current = provider.getSigner();

        toast(`Connected. Current account: ${account.slice(0, 30)}...`);

        if (!isRopstenNetwork || !isChainConnectionHealthy) {
          debugEnvironment();
        }
      })
      .catch((error) => {
        if (error.code === 4001) {
          toast('Denied.');
        }

        if (error.code === -32002) {
          toast('Already requesting accounts. Reload this page if necessary.');
        }
      });
  }, [debugEnvironment, isRopstenNetwork, isChainConnectionHealthy]);

  // sign message handler
  const signUserInput = useCallback(() => {
    const signer = signerRef.current;

    if (!signer) {
      debugEnvironment();

      return;
    }

    setIsSigningUserInput(true);

    signer
      .signMessage(userInput)
      .then((signed) => {
        setSignedUserInput(signed);
      })
      .catch((error: any) => {
        if (error.code === 4001) {
          toast('Denied.');
        } else {
          toast('Error');
        }
      })
      .finally(() => {
        setIsSigningUserInput(false);
      });
  }, [debugEnvironment, userInput]);

  // set contract value handler
  const updateContractValue = useCallback(() => {
    const provider = providerRef.current;
    const contract = contractRef.current;
    const signer = signerRef.current;

    if (!provider || !contract || !signer || !isChainConnectionHealthy) {
      debugEnvironment();

      return;
    }

    setIsSendingTransaction(true);

    contract
      .connect(signer)
      .set(signedUserInput)
      .then((result: any) => {
        const transactionHash = result.hash as string;

        toast(`Transaction ${transactionHash.slice(0, 30)}... has been sent.`);

        provider.once(transactionHash, (transaction) => {
          toast(`Transaction ${transaction.transactionHash.slice(0, 30)}... has been mined.`);

          reloadContractValue(contract, setContractValue);
        });
      })
      .catch((error: any) => {
        if (error.code === 4001) {
          toast('Denied.');
        } else {
          toast('Error.');
        }
      })
      .finally(() => {
        setIsSendingTransaction(false);
      });
  }, [isChainConnectionHealthy, debugEnvironment, signedUserInput]);

  const isSignButtonDisabled = isSigningUserInput || isSendingTransaction;
  const isSendButtonDisabled = isSigningUserInput || isSendingTransaction || !signedUserInput;

  return (
    <>
      <div className="h-16 border-b-2 flex justify-between items-center">
        <span className="ml-5 inline-flex items-center">TEST</span>
        <button type="button" className="btn-enabled mr-4" onClick={connect}>
          connect
        </button>
      </div>
      <div className="mt-10 m-auto w-3/4 flex flex-col">
        <div className="row-container">
          <input
            type="text"
            className="border-2 px-3 rounded-sm min-w-0 flex-auto"
            value={userInput}
            onChange={(event) => setUserInput(event.target.value)}
            placeholder="input"
          />
          <button
            type="button"
            className={`ml-2 flex-none ${
              isSignButtonDisabled
                ? `btn-disabled ${isSigningUserInput ? 'cursor-wait' : 'cursor-not-allowed'}`
                : 'btn-enabled'
            }`}
            onClick={signUserInput}
            disabled={isSignButtonDisabled}
          >
            sign
          </button>
        </div>
        <div className="row-container">
          <span className="inline-flex items-center whitespace-nowrap">Hashed Value:</span>
          <span className="ml-2 display-span">{signedUserInput}</span>
          <button
            type="button"
            className={`ml-2 flex-none ${
              isSendButtonDisabled
                ? `btn-disabled ${isSendingTransaction ? 'cursor-wait' : 'cursor-not-allowed'}`
                : 'btn-enabled'
            }`}
            onClick={updateContractValue}
            disabled={isSendButtonDisabled}
          >
            send
          </button>
        </div>
        <div className="row-container">
          <span className="inline-flex items-center whitespace-nowrap">Variable Value:</span>
          <span className="ml-2 display-span">{contractValue}</span>
        </div>
        <Toaster />
      </div>
    </>
  );
}

export default App;
